<template>
  <VueFinalModal name="profile" v-bind="$attrs" classes="modal-container" content-class="modal-content" @before-open="onBeforeOpen">
    <div v-if="$auth.loggedIn" id="settingsModal" class="box-modal strong settingsModal">
      <div class="box-modal_close arcticmodal-close" @click.prevent="$vfm.hide('profile')">
        <img class="close" src="/icons/close.svg" alt="" />
      </div>
      <div class="title">
        {{ $auth.user.name }}, <span>{{ $t("modal.profile.title") }}</span>
      </div>
      <div class="data">
        <div class="social">
          <div class="items">
            <div class="item">
              <img src="/icons/vkGradient.png" class="item-social vk" alt="" />
              <a v-if="!$auth.user.socials.find((x) => x.provider === 'vk')" class="add" href="#" @click="redirectVk">
                <img src="/icons/plus.svg" alt="" />
              </a>
              <span v-else class="success">
                <img src="/icons/checkWhite.svg" alt="" />
              </span>
            </div>
            <div class="item">
              <img src="/icons/tgGradient.png" class="item-social vk" alt="Telegram" />
              <a class="add" href="#" @click.prevent="$vfm.show('telegram', { connect: true })">
                <img src="/icons/plus.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="userblock">
        <div class="title">{{ $t("modal.profile.email_t") }}</div>
        <div class="data">
          <form class="password" @submit.prevent="onChangeEmail">
            <div class="profile-field">
              <div class="input">
                <input v-model="form.email" :readonly="$auth.user.email?.verified" name="email" required :placeholder="$t('modal.profile.email_p')" />
                <div v-if="$auth.user.email?.verified" class="profile-confirmed">
                  {{ $t("modal.profile.email_conf") }}
                  <FontIcon icon="check" size="20" />
                </div>
              </div>
              <div>
                <button v-if="!$auth.user.email?.email || ($auth.user.email?.email && form.email !== $auth.user.email?.email)" type="submit" class="profile-footer_btn">
                  {{ $t("modal.profile.email_s") }}
                </button>
                <button v-else-if="$auth.user.email?.email && !$auth.user.email?.verified" type="submit" class="profile-footer_btn">
                  {{ $t("modal.profile.email_wait") }}
                </button>
              </div>
            </div>
            <div class="email_a" v-if="!$auth.user.email?.email || ($auth.user.email?.email && form.email !== $auth.user.email?.email)">{{ $t("modal.profile.email_a") }}</div>
            <div class="email_a" v-else-if="$auth.user.email?.email && !$auth.user.email?.verified">{{ $t("modal.profile.email_sended") }}</div>
          </form>
        </div>
      </div>

      <div class="title">{{ $t("modal.profile.password") }}</div>
      <div class="data">
        <form class="password strong" @submit.prevent="onChangePassword">
          <div v-if="!showPassword" class="profile-field">
            <div class="input">
              <input v-model="passwordForm.password" type="password" name="password_old" required :placeholder="$t('modal.profile.password_old')" />
            </div>
            <div>
              <button type="button" @click="onShowPassword" class="profile-footer_btn sender">
                {{ $t("modal.profile.changer") }}
              </button>
            </div>
          </div>
          <template v-if="showPassword">
            <div class="input">
              <input v-model="passwordForm.password" name="password_old" type="password" required :placeholder="$t('modal.profile.password_old')" />
            </div>
            <div class="input">
              <input v-model="passwordForm.new_password" name="password_new" type="password" required :placeholder="$t('modal.profile.password_new')" />
            </div>
            <div class="input">
              <input v-model="passwordForm.password_confirmation" name="password_confirmation" type="password" required :placeholder="$t('modal.profile.password_repeat')" />
            </div>
            <div class="profile-footer" style="width: 100%">
              <button type="submit" class="profile-footer_btn">
                {{ $t("modal.profile.password_submit") }}
              </button>
            </div></template
          >
        </form>
      </div>
      <div class="userblock">
        <div class="title">{{ $t("modal.profile.pincode") }}</div>
        <div class="data">
          <ModalPincodeForm />
        </div>
      </div>

      <div class="userblock">
        <div class="title">
          {{ $t("modal.profile.block_title") }}
          <div class="userblock_faq">
            <div class="i-faq" />
            <div class="userblock_faq_wrap">
              <p v-html="$t('modal.profile.block_faq')" />
            </div>
          </div>
        </div>
        <div v-if="($auth.user.self_exclusion_forms.length && $auth.user.self_exclusion_forms[0].status === 0) || blockProcessing" class="userblock_card light">
          <span> {{ $t("modal.profile.block_pending") }}</span>
          <FontIcon icon="clock" size="20" />
        </div>
        <div v-else-if="banned" class="f">
          <div class="userblock_card">
            <p>
              {{ $t("modal.profile.blocked_of") }}
              <span>{{ getDate(banned.finished_at) }}</span>
            </p>
          </div>
          <button class="bt bt-light" @click="setBlock('unban')">
            <FontIcon icon="unlock" size="20" />
          </button>
        </div>
        <div v-else class="userblock_wrap">
          <div ref="dd" class="cselect ontop" :class="{ open: toggle }">
            <button class="cselect_top" @click="toggle = !toggle">
              <span>{{ period.label ? $t(period.label) : $t("time.choose_period") }}</span>
              <FontIcon icon="arrow" size="16" class="arrow" />
            </button>
            <div class="cselect_dropdown">
              <div class="cselect_overlay" @click="toggle = !toggle" />
              <div class="list cselect__list">
                <button v-for="item in periods" :key="item.time" class="btn" :class="{ active: period === item }" @click="selectPeriod(item)">
                  <span>{{ $t(item.label) }}</span>
                  <FontIcon v-if="period === item" icon="check" size="16" class="check" />
                </button>
              </div>
            </div>
          </div>
          <button class="btn-orange" :disabled="!period.label" @click="setBlock('ban')">
            {{ $t("modal.profile.block_btn") }}
          </button>
        </div>
      </div>
      <a href="#" class="logout_modal_button profile-footer_btn" @click="onLogout">
        {{ $t("modal.profile.logout") }}
      </a>
    </div>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal, $vfm } from "vue-final-modal";
import { telegramLoginTemp } from "vue3-telegram-login";
import utils from "@/effects/useEffects";

export default {
  components: { VueFinalModal, telegramLoginTemp },
  setup() {
    const { $axios, $auth, $showToast } = useNuxtApp();
    const router = useRouter();
    const { t } = useI18n();
    const passwordForm = ref({
      password: null,
      new_password: null,
    });
    const form = ref({
      email: $auth.user.email?.email || null,
    });
    const onChangePassword = async () => {
      await $axios.$post("/auth/changePassword", passwordForm.value);
    };
    const onChangeEmail = async () => {
      if ($auth.user.email?.email === form.value.email) {
        await $axios.$post("/user/email/send");
      } else {
        await $axios.$patch("/user/email/set", form.value).then((x) => {
          if (x.response?.notify?.type === "success") {
            $auth.user.email = {
              email: form.value.email,
              verified: false,
            };
          }
        });
      }
    };
    const localePath = useLocalePath();
    const onLogout = async () => {
      await $axios.$post("/auth/logout").catch(() => {});
      await $auth.logout();
      router.push(localePath("/"));
    };
    const onTelegramCallback = async (e) => {
      if (e) {
        await $axios
          .$post("/auth/social", {
            provider: "tg",
            ...e,
          })
          .then(async ({ response }) => {
            if (response?.access_token) {
              await $auth.setUserToken(response.access_token);
            }
          });
      } else {
        $showToast(t("modal.profile.error"), "error");
      }
    };
    const redirectVk = async () => {
      await $axios.$post("/auth/social", { provider: "vk" }).catch(({ response }) => {
        if (response?.data?.response?.redirect) window.location = response.data.response.redirect;
      });
    };

    const dd = ref(null);
    const toggle = ref(false);
    const blockProcessing = ref(false);
    const period = ref({});
    const periods = ref([
      {
        label: "time.day",
        time: 86400,
      },
      {
        label: "time.week",
        time: 86400 * 7,
      },
      {
        label: "time.month",
        time: 86400 * 30,
      },
    ]);
    const selectPeriod = (p) => {
      period.value = p;
      toggle.value = false;
    };
    const setBlock = async (type) => {
      if (!period.value.time && type === "ban") return;
      const params = { type };
      if (type === "ban") params.time = period.value.time;
      await $axios.$post("/user/selfExclusion", params).then(({ response }) => {
        if (response?.notify?.type === "success") blockProcessing.value = true;
      });
    };
    const documentClick = (event) => {
      let target = event.target;
      if (dd && dd.value && dd.value !== target && !dd.value.contains(target)) {
        toggle.value = false;
      }
    };
    onMounted(() => {
      document.addEventListener("click", documentClick);
    });
    onBeforeUnmount(() => {
      document.removeEventListener("click", documentClick);
    });

    const banned = computed(() => {
      return $auth.user?.bans?.find((el) => el.type === "self:exclusion");
    });
    const getDate = utils.getDate;
    const onBeforeOpen = () => {};
    const showPassword = ref(false);
    function onShowPassword() {
      if (!passwordForm.value.password) return;
      showPassword.value = true;
    }
    return {
      showPassword,
      passwordForm,
      onBeforeOpen,
      onChangePassword,
      redirectVk,
      onTelegramCallback,
      onLogout,
      setBlock,
      period,
      periods,
      toggle,
      selectPeriod,
      dd,
      banned,
      blockProcessing,
      getDate,
      form,
      onChangeEmail,
      onShowPassword,
    };
  },
};
</script>

<style lang="scss">
.email_a {
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: 600;
  color: #9e96ba;
}
.email_s {
  margin-bottom: 20px;
}

html.dark {
  .email_a {
    color: #78819d;
  }
}
.profile {
  &-field {
    display: flex;
    gap: 12px;
    margin-bottom: 8px;

    .input {
      flex: 1;
      margin-bottom: 0 !important;
    }

    button {
      height: 100%;
      width: 160px;
      flex-shrink: 0;
    }
  }
  &-confirmed {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    color: #4dbc4a;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;

    svg {
      margin-left: 8px;
    }
  }
  &-footer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    &_btn {
      flex-grow: 1;
      text-align: center;
      margin: 0;
      font-weight: 700;

      &.green {
        background: #62cb60 !important;
        box-shadow: none !important;
        color: #fff !important;
      }
      &.gray {
        background: var(--primary-400) !important;
        box-shadow: none !important;
        color: var(--primary-800) !important;
      }
    }

    &_toolbar {
      display: flex;
      gap: 10px;
    }

    @media screen and (max-width: 760px) {
      &_btn {
        order: 1;
        width: 100%;
      }

      .logout_modal_button {
        order: 5;
      }

      &_toolbar {
        order: 2;
        margin: 6px 0;
      }
    }
  }
}

.tg-button-face {
  z-index: 10;
  pointer-events: none;
  user-select: none;
}

.tg-button {
  position: absolute;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  bottom: -15px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  z-index: 0;
}

.settingsModal {
  width: 625px !important;

  .btn-orange {
    background: linear-gradient(90deg, #ffc658 -18.18%, #f4a100 49.18%, #ffc658 120%);
    box-shadow: 0px 8px 15px rgba(244, 161, 1, 0.25);
    border-radius: 3px;
    margin: 0 auto;
    font-size: 15px;
    font-weight: 700;
    padding: 15px 30px;
    cursor: pointer;
    width: 100%;
    color: #000;

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.4;
    }
  }

  .bt {
    border: 0;
    padding: 0;
    outline: none;
    border-radius: 4px;
    background: transparent;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.2;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    transition: 0.3s;
    cursor: pointer;

    &.disabled,
    &:disabled {
      pointer-events: none;
    }

    &-light {
      background: #f4f2ff;
      color: #b6b0d7;
      font-weight: 600;
      border-radius: 4px;
      padding: 0 12px;
      min-width: 50px;
      &:hover {
        background: darken(#f4f2ff, 2%);
      }
    }
  }
  .userblock {
    margin-top: 20px;
    .f {
      display: flex;
      gap: 12px;
    }
    .title {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &_faq {
      position: relative;
      .i-faq {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background: #000;
        font-size: 18px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--primary-100);
        color: #b4aed4;
        &:after {
          content: "?";
        }
      }
      &_wrap {
        position: absolute;
        right: 0;
        bottom: 100%;
        background: #f4f2ff;
        border-radius: 4px;
        padding: 12px;
        font-size: 14px;
        color: #3a3f4e;
        min-width: 180px;
        transition: 0.3s;
        background: var(--primary-100);
        box-shadow: 0px -4px 10px rgba(42, 36, 68, 0.1);
        visibility: hidden;
        opacity: 0;
        &:before {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 6px solid var(--primary-100);
          position: absolute;
          bottom: -5px;
          right: 7px;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -12px;
          height: 12px;
        }
      }
      &:hover {
        .userblock_faq_wrap {
          opacity: 1;
          visibility: visible;
          bottom: 130%;
        }
      }
    }
    &_wrap {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);

      @media screen and (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &_card {
      background: #f4f2ff;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      min-height: 50px;
      width: 100%;
      font-weight: 600;
      color: #413b63;
      padding: 8px 12px;
      text-align: center;

      &.light {
        color: #b4aed4;
      }

      @media screen and (max-width: 480px) {
        span {
          display: block;
        }
      }
    }

    @mixin ellipsis() {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .cselect {
      position: relative;
      font-size: 14px;
      width: 100%;
      max-width: initial;

      .arrow,
      .check {
        color: var(--primary-300);
      }

      & > .cselect {
        &_top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;
          padding: 1px 16px;
          height: 50px;
          border-radius: 4px;
          font-weight: 500;
          width: 100%;
          @include ellipsis;
          transition: 0.3s;
          background: var(--primary-100);
          text-align: left;
          font-size: 15px;

          span {
            width: 100%;
            color: var(--primary-800) !important;
          }

          .arrow {
            transition: 0.3s;
          }

          &.transparent {
            background: transparent !important;
          }
        }

        &_dropdown {
          position: absolute;
          z-index: 50;
          top: 110%;
          left: -10px;
          right: -10px;
          padding: 10px;
          opacity: 0;
          visibility: hidden;
          transition: 0.3s;
          min-width: 100%;

          .list {
            background: var(--primary-100);
            border-radius: 4px;
            padding: 3px;
            overflow-y: auto;
            max-height: 430px;
            box-shadow: 0px -4px 10px rgba(42, 36, 68, 0.1);

            .btn {
              @include ellipsis;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding: 12px;
              font-size: 15px;
              font-weight: 500;
              width: 100%;
              background: none;
              gap: 8px;
              text-align: left;
              height: 50px;
              border-radius: 4px;

              span {
                width: 100%;
                color: var(--primary-800) !important;
              }

              &:hover {
                background: var(--primary-bg);
              }

              &.active {
                background: var(--primary-bg);
              }
            }
          }
        }
      }

      &.open {
        & > .cselect {
          &_top {
            .arrow {
              transform: rotate(180deg);
            }
          }

          &_dropdown {
            top: 100%;
            opacity: 1;
            visibility: visible;
          }
        }

        &.ontop {
          & > .cselect {
            &_dropdown {
              top: inherit;
              bottom: 100%;
            }
          }
        }
      }

      &.ontop {
        & > .cselect {
          &_dropdown {
            top: inherit;
            bottom: 90%;
          }
        }
      }
    }
  }
}

.theme__dark {
  .settingsModal {
    .bt {
      &-light {
        background: #3a3f4e;
        color: #78819d;

        &:hover {
          background: darken(#434959, 2%);
        }
      }
    }
    .userblock {
      &_card {
        background: #3a3f4e;
        color: #f2f0ff;

        &.light {
          color: #78819d;
        }
      }
      &_faq {
        .i-faq {
          background: var(--primary-1);
        }
        &_wrap {
          background: var(--primary-1);
          color: #f2f0ff;
          &:before {
            border-top-color: var(--primary-1);
          }
        }
      }

      .cselect {
        & > .cselect_top {
          background: #3a3f4e;
        }

        & > .cselect_dropdown .list {
          background: #3a3f4e;
        }
      }
    }
  }
}

html.theme__dark {
  .settingsModal .input input {
    color: #fff !important;
  }
}
</style>
